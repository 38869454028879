<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <div class="space-between">
          <div>
            <span>业务编号: {{ form.serial_num }}</span>
          </div>
          <div>
            <a-button>
              <router-link :to="{ name: 'WmsWarehouseRent' }">取消</router-link>
            </a-button>
            <a-divider type="vertical" />
            <a-button type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
          </div>
        </div>
      </div>

      <a-form-model
        layout="vertical"
        :model="form"
        :rules="rules"
        ref="form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="客户" prop="customer_id">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="租赁开始时间" prop="lease_start_date">
              <a-date-picker style="width: 100%" v-model="form.lease_start_date" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="租赁结束时间" prop="lease_end_date">
              <a-date-picker style="width: 100%" v-model="form.lease_end_date" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-card class="card" title="其他信息" :bordered="false">
      <a-tabs :activeKey="activeKey" @change="key => { activeKey = key }">
        <a-tab-pane tab="费用信息" key="1">
          <edit-table
            :columns="feeColumns"
            :sourceData="feeData"
            @getTableDate="getFeeData" />
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import { getCommonOptions, getCustomer } from '@/api/common';
import { EditTable } from '@/components';
import preventReClick from '@/utils/preventReClick';
import debounce from 'lodash/debounce';
import { saveOrUpdateWmsWarehouseRent, getWmsWarehouseRentDetailById } from '@/api/wms';

export default {
  components: {
    EditTable, preventReClick
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      form: {
        customer_id: undefined,
        lease_start_date: undefined,
        lease_end_date: undefined
      },
      rules: {
        customer_id: [{ required: true, message: '请选择客户', trigger: 'blur' }]
        // lease_start_date: [{ required: true, message: '请选择租赁开始时间', trigger: 'blur' }],
        // lease_end_date: [{ required: true, message: '请选择租赁结束时间', trigger: 'blur' }]
      },
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      activeKey: '1',
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 1, type: 1 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        // {
        //   title: '付款方式',
        //   dataIndex: 'charge_type',
        //   extra: {
        //     type: 'select',
        //     defaultValue: 1,
        //     options: [
        //       { value: 1, name: '现金' },
        //       { value: 2, name: '月结' }
        //     ],
        //     disabled: record => {
        //       return record.disabled
        //     }
        //   }
        // },
        {
          title: '金额',
          dataIndex: 'amount',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            return text && this.GLOBAL.feeStatusMaps[text].name
          }
        }
      ],
      feeData: [],
      disabled: false,
      isEdit: false
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false;
      if (!this.queryParam && newRoute.name === 'WmsWarehouseRentCUR') {
        // 编辑页面由监控
        this.handleFormReset();
        if (newRoute.params.id === 'create') {
          // 新增
          this.isEdit = false;
          this.$route.meta.title = '新增仓储租赁';
        } else {
          // 编辑
          this.isEdit = true;
          this.$route.meta.title = '编辑仓储租赁';
          this.disabled = newRoute.query.disabled;
          this.getEditData(newRoute.params.id);
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.isEdit = true;
      this.$route.meta.title = '编辑仓储租赁';
      this.disabled = this.$route.query.disabled;
      this.getEditData(this.$route.params.id);
    }
  },
  created() {
    this.handleFormReset();
  },
  methods: {
    getCommonOptions,
    getEditData(id) {
      getWmsWarehouseRentDetailById({ id: id }).then(v => {
        this.customerInfo = {
          key: v.customer_id,
          label: v.customer_name
        }
        this.form = v;
        this.feeData = v.fee_info_list ? v.fee_info_list.map(fee => {
            if (fee.fee_status > 2 && fee.fee_status !== 4) {
              fee.disabled = true;
            } else {
              fee.disabled = false;
            }
            return {
              id: fee.id,
              fee_id: fee.fee_id,
              charge_type: fee.charge_type,
              amount: fee.amount,
              disabled: fee.disabled,
              fee_status: fee.fee_status
            }
          }) : []
      });
    },
    handleFormReset() {
      this.form = {
        customer_id: undefined,
        lease_start_date: undefined,
        lease_end_date: undefined
      }
      this.customerInfo = undefined;
      this.feeData = [];
      this.$nextTick(_ => {
        this.$refs.form.clearValidate();
      })
    },
    getFeeData(data) {
      this.feeData = data
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        this.form['customer_id'] = undefined;
      } else {
        this.form['customer_id'] = value.key;
      }
      this.customerInfo = value;
      this.customerOps = [];
      this.fetching = false;
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          for (const item of this.feeData) {
            if (item.fee_status === '') {
              item.fee_status = null
            }
          }
          // 构建请求参数
          const data = {
            id: this.form.id,
            customer_id: this.form.customer_id,
            lease_start_date: this.form.lease_start_date ? this.form.lease_start_date.valueOf() : null,
            lease_end_date: this.form.lease_end_date ? this.form.lease_end_date.valueOf() : null,
            remark: this.form.remark,
            fee_info_list: this.feeData
          }
          // 发送请求
          saveOrUpdateWmsWarehouseRent(data).then(res => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            });
            this.$router.push({
              name: 'WmsWarehouseRent',
              query: {
                isEdit: this.isEdit
              }
            });
          });
        }
      });
    }
  }
}
</script>

<style scoped>
  .space-between {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
